import { render, staticRenderFns } from "./ZtPhone.vue?vue&type=template&id=4adefc27&scoped=true&"
import script from "./ZtPhone.vue?vue&type=script&lang=js&"
export * from "./ZtPhone.vue?vue&type=script&lang=js&"
import style0 from "./ZtPhone.vue?vue&type=style&index=0&id=4adefc27&prod&scoped=true&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4adefc27",
  null
  
)

export default component.exports